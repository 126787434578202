<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <!--Status-->
          <v-card outlined class="d-flex justify-space-between">
            <div v-if="!editStatus">
              <template v-if="Lo_Question.status === 'Definitiv'">
                <v-chip
                  class="ma-1 mt-2"
                  color="success"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left> mdi-check </v-icon>
                  Staus: {{ Lo_Question.status }}
                </v-chip>
              </template>
              <template v-else>
                <v-chip
                  class="ma-1 mt-2"
                  color="warning"
                  label
                  small
                  text-color="white"
                >
                  Staus: {{ Lo_Question.status }}
                </v-chip>
              </template>
            </div>
            <div v-if="editStatus">
              <v-select
                class="pa-3"
                dense
                :items="La_Status"
                label="Status"
                @change="changeStaus"
              ></v-select>
            </div>
            <div class="mr-1">
              <v-tooltip bottom v-if="!editStatus">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="success"
                    @click="editStatus = true"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
              <v-tooltip bottom v-if="editStatus">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="success"
                    @click="editStatus = false"
                  >
                    <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </template>
                <span>speicheren</span>
              </v-tooltip>
            </div>
          </v-card>
          <!--End--Status-->
          <!--Nummer-->
          <div>
            <v-card outlined class="pa-1 my-1">
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row dense" v-if="!editNummer">
                  <h5 v-if="!editNummer">Nr.</h5>
                  <h5
                    v-if="!editNummer"
                    v-html="Lo_Question.nummer"
                    class="font-weight-bold"
                  />
                </div>
                <v-tooltip bottom v-if="!editNummer">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editNummer = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
                <h5 v-if="editNummer">Nr.</h5>
                <v-tooltip bottom v-if="editNummer">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editNummer = false"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>speicheren</span>
                </v-tooltip>
              </div>
              <tiptap-vuetify
                v-if="editNummer"
                v-model="Lo_Question.nummer"
                :extensions="NrExtensions"
              />
            </v-card>
          </div>
          <!--End--Nummer-->
          <!--Rolle der Kandidatin-->
          <div>
            <v-card outlined class="pa-1 my-1">
              <div class="d-flex justify-space-between">
                <h5 class="font-weight-bold">
                  Rolle der Kandidatin / des Kandidaten:
                </h5>
                <v-tooltip bottom v-if="!editRolle">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editRolle = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editRolle">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editRolle = false"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>speicheren</span>
                </v-tooltip>
              </div>
              <div class="d-flex justify-space-between">
                <div v-if="!editRolle" v-html="Lo_Question.rolleDesKandidat" />
              </div>
              <tiptap-vuetify
                v-if="editRolle"
                v-model="Lo_Question.rolleDesKandidat"
                :extensions="extensions"
              />
            </v-card>
          </div>
          <!--End--Rolle der Kandidatin-->
          <!--Ausgangssituation-->
          <div>
            <v-card outlined class="pa-1 my-1">
              <div class="d-flex justify-space-between">
                <h5 class="font-weight-bold">Ausgangssituation:</h5>
                <v-tooltip bottom v-if="!editAusgang">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editAusgang = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editAusgang">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editAusgang = false"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>speicheren</span>
                </v-tooltip>
              </div>
              <div v-if="!editAusgang" v-html="Lo_Question.ausgangssituation" />
              <tiptap-vuetify
                v-if="editAusgang"
                v-model="Lo_Question.ausgangssituation"
                :extensions="extensions"
              />
            </v-card>
          </div>
          <!--End--Ausgangssituation-->
          <v-divider class="my-4" />
          <div class="my-4" />
          <!--Frage an den Kandidaten/an die Kandidatin:-->
          <div>
            <h5 class="font-weight-bold">
              Fragen an den Kandidaten / an die Kandidatin:
            </h5>
            <v-card
              outlined
              class="pa-1 my-1"
              v-for="(frage, index) in Lo_Question.fragen"
              :key="index"
            >
              <div class="d-flex justify-space-between">
                <h6>Frage: {{ index + 1 }}</h6>
                <div>
                  <v-tooltip bottom v-if="!editFragen">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="success"
                        @click="editFragen = true"
                      >
                        <v-icon>mdi-pencil-box-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Bearbeiten</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="editFragen">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="success"
                        @click="editFragen = false"
                      >
                        <v-icon>mdi-content-save-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>speicheren</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="danger"
                        @click="dialog = true"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Löschen</span>
                  </v-tooltip>
                </div>
              </div>

              <div v-if="!editFragen" class="" v-html="frage.frage" />
              <tiptap-vuetify
                v-if="editFragen"
                v-model="frage.frage"
                :extensions="extensions"
              />
              <div v-if="!editFragen" v-html="frage.ErwarteteAntworten" />
              <tiptap-vuetify
                class="my-1"
                v-if="editFragen"
                v-model="frage.ErwarteteAntworten"
                :extensions="extensions"
              />
              <v-dialog v-model="dialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Sie werden eine Frage Löschen!
                  </v-card-title>
                  <v-card-text class="black--text"
                    >Möchten Sie fortfahren?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">
                      Nein
                    </v-btn>
                    <v-btn color="red darken-1" text @click="deleteItem(index)">
                      Löschen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
            <base-hover-button
              color="primary lighten-5"
              text-color="primary--text"
              text="Neue Frage"
              @click.native="addItem()"
              iconName=" mdi-plus"
            />
            <!-- <base-hover-button
              color="warning lighten-5"
              text="Undo"
              textColor="warning--text"
              @click.native="addItem()"
            />-->
          </div>
          <!--End--Frage an den Kandidaten/an die Kandidatin:-->
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import {
  // component
  TiptapVuetify,
  // extensions
  Heading,
  Bold,
  Italic,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "tiptap-vuetify";
export default {
  name: "EditQuestions",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Edit Questions",
  },
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      dialog: false,
      editStatus: false,
      editNummer: false,
      editRolle: false,
      editAusgang: false,
      editFragen: false,
      newQuestion: {
        frage: "frage..",
        ErwarteteAntworten: "ErwarteteAntworten..",
      },
      //Tiptap-vuetify
      extensions: [
        History,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        Underline,
        Italic,
        ListItem, // if you need to use a list (BulletList, OrderedList)
        BulletList,
        OrderedList,
        Image,
        [
          Heading,
          {
            // Options that fall into the tiptap's extension
            options: {
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak, // line break on Shift + Ctrl + Enter
      ],
      NrExtensions: [
        History,
        [
          Heading,
          {
            // Options that fall into the tiptap's extension
            options: {
              levels: [5],
            },
          },
        ],
      ],
      La_Status: ["Definitiv", "QSK", "Entwurf", "In Übersetzung"],
      Lo_Question: {
        status: "",
        nummer: "",
        rolleDesKandidat: "",
        ausgangssituation: "",
        fragen: [
          {
            frage: "",
            ErwarteteAntworten: "",
          },
          {
            frage: "",
            ErwarteteAntworten: "",
          },
        ],
      },
    };
  },
  methods: {
    addItem: function () {
      this.Lo_Question.fragen.push(this.newQuestion);
    },
    deleteItem: function (deleteItem) {
      this.Lo_Question.fragen.splice(deleteItem, 1);
      this.dialog = false;
    },
    changeStaus: function (As_status) {
      this.Lo_Question.status = As_status;
    },
  },
};
</script>
